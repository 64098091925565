"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isAllLowerCase = void 0;
/**
 * @param value The value to be checked if it is all lowercase or not
 */
function isAllLowerCase(value) {
    if (!value)
        throw new ReferenceError("Value Parameter must be specified");
    if (typeof value !== "string")
        value = String(value);
    return value == value.toLowerCase();
}
exports.isAllLowerCase = isAllLowerCase;

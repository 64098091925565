"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isCapitalized = void 0;
var capitalize_1 = require("./capitalize");
/**
 * @param value The value to be checked
 * @param eachWord This indicates whether each word should be checked or just the first. If no value is specified this is false
 */
function isCapitalized(value, eachWord) {
    if (eachWord === void 0) { eachWord = false; }
    if (!value)
        throw new ReferenceError("Value Parameter must be specified");
    if (typeof eachWord !== "boolean")
        throw new TypeError("Each Word Parameter must be a boolean");
    if (typeof value !== "string")
        value = String(value);
    if (!eachWord) {
        return value == capitalize_1.capitalize(value);
    }
    else {
        return value == capitalize_1.capitalize(value, true);
    }
}
exports.isCapitalized = isCapitalized;

"use strict";
/**
 * @param words What words should be joined?
 * @param separator What should be used to seperate each word? If no value is specified the seperator is ""
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.join = void 0;
function join(words, separator) {
    if (separator === void 0) { separator = ""; }
    if (!Array.isArray(words))
        throw new ReferenceError("Words parameter must be an array");
    if (words.length <= 1)
        throw new ReferenceError("Words parameter must have 2 or more words");
    var result = words.join(separator);
    return result;
}
exports.join = join;

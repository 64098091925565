"use strict";
/**
 * @param length Length of random string
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.randomString = void 0;
function randomString(length) {
    if (!length)
        throw new ReferenceError("Length Parameter is missing");
    if (length < 0)
        throw new TypeError("Length Parameter must be a positive number");
    if (isNaN(length) || length == NaN)
        throw new TypeError("Length Parameter must be a number");
    var characters = [];
    var string = "";
    //genertating the chars
    for (var index = 32; index < 127; index++) {
        characters.push(String.fromCharCode(index));
    }
    //randomizing string
    for (var index = 0; index < length - 1; index++) {
        var rng = Math.floor(Math.random() * characters.length);
        string += characters[rng];
    }
    return string;
}
exports.randomString = randomString;

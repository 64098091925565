"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.capitalize = void 0;
/**
 * @param value The value to be capitalized
 * @param eachWord This indicates whether each word should be capitalized or just the first. If no value is specified this is false
 */
function capitalize(value, eachWord) {
    if (eachWord === void 0) { eachWord = false; }
    if (!value)
        throw new ReferenceError("Value Parameter must be specified");
    if (typeof eachWord !== "boolean")
        throw new TypeError("Each Word Parameter must be a boolean");
    if (typeof value !== "string")
        value = String(value);
    if (!eachWord) {
        return value.charAt(0).toUpperCase() + value.slice(1);
    }
    else {
        var sepRegex = new RegExp(/\.|_|-| |,/);
        var seperators = [];
        var capitalWords = [];
        var final = [];
        for (var _i = 0, _a = value.split(""); _i < _a.length; _i++) {
            var letter = _a[_i];
            if (sepRegex.test(letter))
                seperators.push(letter);
        }
        var words = value.split(sepRegex);
        for (var _b = 0, words_1 = words; _b < words_1.length; _b++) {
            var word = words_1[_b];
            capitalWords.push(word.charAt(0).toUpperCase() + word.slice(1));
        }
        var l = Math.min(capitalWords.length, seperators.length);
        for (var i = 0; i < l; i++) {
            final.push(capitalWords[i], seperators[i]);
        }
        final.push.apply(final, __spreadArray(__spreadArray([], capitalWords.slice(l)), seperators.slice(l)));
        return final.join("");
    }
}
exports.capitalize = capitalize;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.reverse = void 0;
/**
 * @param word What word(s) should be reversed?
 */
function reverse(word) {
    if (!word)
        throw new ReferenceError("Word Parameter is missing");
    var arr = String(word).split("").reverse();
    return arr.join("");
}
exports.reverse = reverse;

"use strict";
/**
 * @param word Word(s) to scramble
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.scramble = void 0;
function scramble(word) {
    if (!word)
        throw new ReferenceError("Word Parameter is missing");
    var shuffledWord = "";
    var words = String(word).split("");
    while (words.length > 0) {
        shuffledWord += words.splice((words.length * Math.random()) << 0, 1);
    }
    return shuffledWord;
}
exports.scramble = scramble;

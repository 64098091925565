"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isString = void 0;
/**
 * @param value The value to be checked whether it is a string or not
 * @param checkForArray This indicates whether the function should return true if the value is a string array. If no value is specified, this is true
 */
function isString(value, checkForArray) {
    if (checkForArray === void 0) { checkForArray = true; }
    if (typeof checkForArray !== "boolean")
        throw new TypeError("Check For Array Parameter must be a boolean");
    if (typeof value === "string") {
        return true;
    }
    else {
        if (!Array.isArray(value))
            return false;
        if (checkForArray) {
            if (!value[0])
                return false;
            return value.every(function (value) { return typeof value === "string"; });
        }
        else
            return false;
    }
}
exports.isString = isString;
